import { Box, Icon, notif, Stack, useBreakpoint, useConfig, useDarkMode } from "@mailbrew/uikit";
import useGenerateBrew from "hooks/useGenerateBrew";
import Link from "next/link";
import { useRouter } from "next/router";
import urls from "urls";
import confirmPromise from "utils/confirmPromise";
import { pluralize } from "utils/pluralize";
import stretchStyle from "utils/stretchStyle";
import BrewManageOptionsDropdown from "./BrewManageOptionsDropdown";
import { PillButton } from "./PillButton";
import StyledTooltip from "./StyledTooltip";

const BrewIssueControls = ({ issue, onDateBackClick, onDateForwardClick, isLatestBrewIssue, isOldestBrewIssue }) => {
  const hits = useBreakpoint([375, 400]);
  const isMobile = hits[1];
  const smallerDevices = hits[0];
  const router = useRouter();

  const brew = issue?.newsletter;

  const { startGeneration, isGenerating } = useGenerateBrew(brew?.id, (issue, notifID) => {
    notif.success("Enjoy your brew 🙌", { duration: 5000, id: notifID });
    router.push(urls.brews() + "?navigateToLatestIssue=true", urls.brews());
  });

  async function handleGenerateBrew() {
    if (brew?.subscribers_count > 0) {
      const confirmed = await confirmPromise(
        `${brew?.subscribers_count} ${pluralize(
          "subscriber",
          brew?.subscribers_count
        )} will get this brew. Are you sure?`
      );
      if (confirmed) {
        startGeneration();
      }
    } else {
      startGeneration();
    }
  }

  if (!issue) return null;

  return (
    <Stack w="100%" align="center" gap={1.8}>
      <Stack
        maxW="100%"
        overflow="hidden"
        noWrap
        gap={isMobile ? 1.5 : 2.0}
        py="2px"
        align="center"
        style={{ flex: "0 0 auto", zoom: smallerDevices ? 0.95 : 1 }}
      >
        <PillButton stretch style={{ paddingLeft: "1px", paddingRight: "1px", cursor: "default" }}>
          <Stack gap={0} overflow="hidden">
            <ChangeIssueButton next={false} onClick={() => onDateBackClick()} disabled={isOldestBrewIssue} />
            <Separator />
            <Stack gap={1} px={1}>
              <StyledTooltip title="Open issue">
                <Link
                  href={
                    isLatestBrewIssue
                      ? urls.viewNewsletter(brew.share_url)
                      : urls.viewNewsletterIssue(brew.share_url, issue.serial_number)
                  }
                  passHref
                >
                  <a style={{ fontVariantNumeric: "tabular-nums", padding: "4px", ...stretchStyle }}>
                    {isMobile ? "#" : "Issue "}
                    {issue.serial_number}
                  </a>
                </Link>
              </StyledTooltip>
            </Stack>
            <Separator />
            <ChangeIssueButton next={true} onClick={() => onDateForwardClick()} disabled={isLatestBrewIssue} />
          </Stack>
        </PillButton>

        {/* <PublishBrewButton key={newsletter.id} variant="pill" newsletter={newsletter} /> */}

        <PillButton icon={!isMobile && "settingsBold"} onClick={() => router.push(urls.editNewsletter(brew.id))}>
          Edit
        </PillButton>

        <BrewManageOptionsDropdown
          newsletter={brew}
          forceIsSelf
          handleGenerateNewIssue={!isGenerating && handleGenerateBrew}
        />
      </Stack>
    </Stack>
  );
};

const Separator = () => {
  const config = useConfig();
  return <Box w="1px" h="24px" bg={config.colors.c6} />;
};

const ChangeIssueButton = ({ next, onClick, disabled }) => {
  const config = useConfig();
  const [darkMode] = useDarkMode();
  return (
    <Stack style={{ padding: "0 6px" }} onClick={onClick}>
      <Icon
        color={disabled ? (darkMode ? config.colors.c4 : config.colors.c5) : config.colors.c2}
        name={next ? "chevronRight" : "chevronLeft"}
        size="18px"
        style={{ flex: "0 0 auto", cursor: disabled ? "default" : "pointer" }}
        disabled={disabled}
      />
    </Stack>
  );
};

export default BrewIssueControls;
