import { Box, useConfig } from "@mailbrew/uikit";

const CustomSection = ({ children, style, ...otherProps }) => {
  const config = useConfig();
  return (
    <Box
      w={config.layout.width}
      maxW="100%"
      mx="auto"
      mt={2}
      flex
      fd="column"
      ai="stretch"
      jc="flex-start"
      h="100%"
      style={{ flex: 1, ...style }}
      {...otherProps}
    >
      {children}
    </Box>
  );
};

export default CustomSection;
