import { Box, Button, HStack, P1, P2, Section, Spinner, Stack, useConfig } from "@mailbrew/uikit";
import BrewIssueControls from "components/BrewIssueControls";
import CustomSection from "components/CustomSection";
import EmptyStateCard from "components/EmptyStateCard";
import NewBrewSmallButton from "components/NewBrewSmallButton";
import OwnerBrewIssueViewer from "components/OwnerBrewIssueViewer";
import Page from "components/Page";
import ReadEditBrewsButton from "components/ReadEditBrewsButton";
import TabsPicker from "components/TabsPicker";
import TimezoneDetector from "components/TimezoneDetector";
import api from "dependencies/api";
import loggedInPage from "hoc/loggedInPage";
import { useRouter } from "next/router";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import useSWR from "swr";
import urls from "urls";
import apiErrMsg from "utils/apiErrMsg";
import { fromNowOrDate } from "../utils/formatDate";
import { BrewEmptyStateCard } from "./manage";

const BrewsPage = () => {
  const config = useConfig();

  const router = useRouter();
  const { navigateToLatestIssue } = router.query ?? {};

  const { data: issueTimeline, mutate } = useSWR("/brew_timeline/?size=20", pageSWRConfig);
  const hasNoIssues = issueTimeline?.results.length === 0;
  const issues = issueTimeline?.results ?? [];

  const { data: brews } = useSWR("/newsletters/", pageSWRConfig);
  const hasNoBrews = brews?.length === 0;

  /* ---------------------- Current Brew and Issues --------------------- */

  const [selectedBrewID, setSelectedBrewID] = useState(null);
  const filteredTimeline = issues.filter((i) => i.newsletter.id === selectedBrewID);
  const { data: selectedBrewIssues = filteredTimeline } = useSWR(
    selectedBrewID && `/issues/?newsletter=${selectedBrewID}&extended=true`
  );

  const [selectedBrewIssueIndex, setSelectedBrewIssueIndex] = useState(0);
  const currentBrewIssue = selectedBrewIssues[selectedBrewIssueIndex];

  const handleBrewClick = useCallback((brewID) => setSelectedBrewID(brewID), []);

  // Navigate to latest fetched issue when navigateToLatestIssue=true

  useEffect(() => {
    if (navigateToLatestIssue && issueTimeline) {
      setSelectedBrewID(issueTimeline?.results[0]?.newsletter.id);
    }
  }, [issueTimeline, navigateToLatestIssue]);

  /* ---------------------- Handle moving between issues ---------------------- */

  function goToPreviousIssue() {
    if (selectedBrewIssues[selectedBrewIssueIndex + 1]) {
      setSelectedBrewIssueIndex(selectedBrewIssueIndex + 1);
    }
  }
  function goToNextIssue() {
    if (selectedBrewIssues[selectedBrewIssueIndex - 1]) {
      setSelectedBrewIssueIndex(selectedBrewIssueIndex - 1);
    }
  }

  const isLatestBrewIssue = !selectedBrewIssues[selectedBrewIssueIndex - 1];
  const isOldestBrewIssue = selectedBrewIssueIndex === selectedBrewIssues.length - 1;

  /* -------------------- Determine Brews to show in header ------------------- */

  const brewsByMostRecent = getBrewsOrderedByMostRecent(issues, brews);
  const showBrewsPicker = brewsByMostRecent && brewsByMostRecent.length > 1;

  /* -------------------- Reset selected issue id when selecting another brew -------------------- */

  useEffect(() => {
    if (selectedBrewIssueIndex) setSelectedBrewIssueIndex(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBrewID]);

  /* ----------------- Show a brew when there's not current ID ---------------- */

  useEffect(() => {
    if (brewsByMostRecent && brewsByMostRecent.length > 0 && !selectedBrewID) {
      setSelectedBrewID(brewsByMostRecent[0]?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brewsByMostRecent]);

  /* -------------------------- Show Bottom Controls -------------------------- */

  const [bottomControlsShown, setBottomControlsShown] = useState(false);

  useEffect(() => {
    setBottomControlsShown(false);
    setTimeout(() => {
      setBottomControlsShown(true);
    }, 500);
  }, [currentBrewIssue]);

  /* --------------------------------- Render --------------------------------- */

  if (hasNoBrews) {
    return (
      <PagePrivate fullTitle="Mailbrew">
        <Section center>
          <BrewEmptyStateCard />
        </Section>
      </PagePrivate>
    );
  } else if (hasNoIssues) {
    return (
      <PagePrivate fullTitle="Mailbrew">
        <CustomSection h="auto" style={{ flex: 0 }} center>
          <TimezoneDetector />
        </CustomSection>
        <Section center>
          <NoIssuesEmptyStateCard onFirstIssueGenerated={() => mutate()} />
        </Section>
      </PagePrivate>
    );
  } else {
    return (
      <PagePrivate background={!showBrewsPicker ? config.colors.bg0 : config.colors.bg1} fullTitle={"Mailbrew"}>
        <CustomSection
          background={config.colors.bg0}
          w="100%"
          mt={0}
          style={{
            flex: "9999 0 auto",
          }}
        >
          <TimezoneDetector background={!showBrewsPicker ? config.colors.bg0 : config.colors.bg1} />
          {showBrewsPicker && (
            <TabsPicker
              items={brewsByMostRecent}
              onTabChange={handleBrewClick}
              active={selectedBrewID}
              tabKey="id"
              tabNameKey="title"
            />
          )}
          {currentBrewIssue && (
            <Fragment>
              <Box mt={showBrewsPicker ? 5 : 1} w="676px" maxW="100%" mx="auto">
                <BrewIssueControls
                  issue={currentBrewIssue}
                  onDateBackClick={goToPreviousIssue}
                  onDateForwardClick={goToNextIssue}
                  isLatestBrewIssue={isLatestBrewIssue}
                  isOldestBrewIssue={isOldestBrewIssue}
                />
                <P2 color={config.colors.c4} textAlign="center" mt={4} mb={3}>
                  Brewed {fromNowOrDate(currentBrewIssue.publication_date)}.
                </P2>{" "}
              </Box>

              <OwnerBrewIssueViewer key={currentBrewIssue.id} issue={currentBrewIssue} />
            </Fragment>
          )}
          {bottomControlsShown && currentBrewIssue && (
            <Stack align="center" mb={4}>
              <BrewIssueControls
                issue={currentBrewIssue}
                onDateBackClick={goToPreviousIssue}
                onDateForwardClick={goToNextIssue}
                isLatestBrewIssue={isLatestBrewIssue}
                isOldestBrewIssue={isOldestBrewIssue}
              />
            </Stack>
          )}
        </CustomSection>
      </PagePrivate>
    );
  }
};

const NoIssuesEmptyStateCard = ({ onFirstIssueGenerated }) => {
  const router = useRouter();
  const [STATUS_START, STATUS_GENERATING, STATUS_FAILURE] = [0, 1, 2];
  const [status, setStatus] = useState(STATUS_START);

  const [generationFailureMessage, setGenerationFailureMessage] = useState(null);
  const [showEditButton, setShowEditButton] = useState(false);

  const [generationRequestError, setGenerationRequestError] = useState(null);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [issueID, setIssueID] = useState(null);
  const [brewID, setBrewID] = useState(null);

  useEffect(() => {
    // start generation automatically after onboarding
    if (router.query?.autogenerate === "true") {
      handleGenerateFirstIssue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query]);

  const handleGenerateFirstIssue = () => {
    setRequestInProgress(true);
    setGenerationRequestError(null);

    api
      .post("/generate_first_issue/")
      .then((res) => {
        setIssueID(res.data.issue_id);
        setBrewID(res.data.brew_id);
        setStatus(STATUS_GENERATING);
      })
      .catch((err) => {
        setGenerationRequestError(err.response?.data?.detail);
      })
      .finally(() => setRequestInProgress(false));
  };

  useSWR(status === STATUS_GENERATING && issueID && `/issues/${issueID}/generation_status/`, {
    refreshInterval: 1000,
    shouldRetryOnError: false,
    onSuccess: (data) => {
      const status = data.status;

      const success = ["published"].includes(status);
      const failed = ["generation_failed", "generation_failed", "no_content"].includes(status);

      if (success) {
        onFirstIssueGenerated();
      } else if (failed) {
        const msg =
          status === "no_content"
            ? "Your brew has no content. Add more sources to it and retry."
            : `Generation failed (${status})`;

        setStatus(STATUS_FAILURE);
        setGenerationFailureMessage(msg);
        setShowEditButton(true);
      }
    },
    onError: (err) => {
      setStatus(STATUS_FAILURE);
      setGenerationFailureMessage(apiErrMsg(err));
    },
  });

  if (status === STATUS_START) {
    return (
      <EmptyStateCard
        showLogo
        title={`Read your first brew`}
        description={`We'll email your brews automatically every day, but you can read the first one immediately.`}
      >
        <HStack align="center" noWrap>
          <Button w="220px" glow icon="refresh" onClick={handleGenerateFirstIssue} loading={requestInProgress}>
            Generate first brew
          </Button>
        </HStack>
        {generationRequestError && (
          <P1 mt={4} color={(c) => c.colors.error}>
            {generationRequestError}
          </P1>
        )}
      </EmptyStateCard>
    );
  } else if (status === STATUS_GENERATING) {
    return (
      <EmptyStateCard
        showLogo
        title={`Preparing your first brew...`}
        description={`Say hello to your new information diet.<br/>You'll be able to read your brew here and via email.`}
      >
        <HStack align="center" noWrap>
          <Spinner />
        </HStack>
      </EmptyStateCard>
    );
  } else if (status === STATUS_FAILURE) {
    return (
      <EmptyStateCard
        showLogo
        title="Something went wrong 😕"
        description={
          generationFailureMessage ?? "We couldn't generate your brew. Please reload the page or contact us."
        }
      >
        {showEditButton && (
          <Button glow icon="writeBold" onClick={() => router.push(urls.editNewsletter(brewID))}>
            Edit brew
          </Button>
        )}
      </EmptyStateCard>
    );
  }
};

const PagePrivate = ({ children, fullTitle, background }) => {
  const config = useConfig();
  return (
    <Page
      fullTitle={fullTitle}
      background={background || config.colors.bg1}
      showMonetizationBanner
      showTimedOffer
      leftHeaderComponents={
        <Stack noWrap>
          <ReadEditBrewsButton mode="edit" />
          <NewBrewSmallButton />
        </Stack>
      }
    >
      {children}
    </Page>
  );
};

function getBrewsOrderedByMostRecent(issues, brews) {
  if (!issues || !brews) return null;
  // This works because issues are ordered chronologically
  const issuesBrewIDs = [...new Set(issues.map((i) => i.newsletter.id))];
  return issuesBrewIDs.map((id) => brews.find((n) => n.id === id));
}

const pageSWRConfig = {
  revalidateOnFocus: true,
  refreshInterval: 300_000, // 5 mins
};

export default loggedInPage(BrewsPage);
